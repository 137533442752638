import React from "react";

function Address({onClickBack}) {
    return (
        <React.Fragment>
            <label for="txtStreetAddress1" className="form-label mt-2">Street address 1</label>
            <input id="txtStreetAddress1" type="text" placeholder="123 Cherry Lane" className="form-control" />
            <label for="txtStreetAddress2" className="form-label mt-2">Street address 2</label>
            <input id="txtStreetAddress2" type="text" placeholder="Apartment 001" className="form-control" />
            <div className="row">
                <div className="col">
                    <label for="txtCity" className="form-label mt-2">City</label>
                    <input id="txtCity" type="text" placeholder="Cookeville" className="form-control" />
                </div>
                <div className="col">
                    <label for="txtState" className="form-label mt-2">State</label>
                    <select id="txtState" className="form-select" aria-label="select state">
                        <option selected className="text-secondary">select</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </select>
                </div>
                <div className="col">
                <label for="txtZipCode" className="form-label mt-2">Zip</label>
                <input id="txtZipCode" type="text" placeholder="38501" className="form-control" />
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-warning mt-4" onClick={onClickBack}>Back</button>
                <button type="button" className="btn btn-success mt-4">Register</button>
            </div>
        </React.Fragment>
    );
}

export default Address;
