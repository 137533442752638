import React from "react";

function SearchInstructions() {
  return (
    <React.Fragment>
      <h2 className="mt-2 text-center">Search for a client!</h2>
      <p>Enter details and click the search button</p>
    </React.Fragment>
  );
}

export default SearchInstructions;
